export default {
	header: [
		{ name: 'About us', link: { href: '/#about' } },
		{ name: 'Export', link: { href: '/#export' } },
		{ name: 'Contacts', link: { href: '/#contacts' } }
	],
	terms: [
		{ name: 'User Agreement', link: { href: '/user-agreement' } },
		{ name: 'Cookies', link: { href: '/user-agreement/cookies' } }
		// { name: 'Terms of Use', link: { href: '/user-agreement/terms-of-use' } },
		// { name: 'Cookies', link: { href: '/user-agreement/cookies' } }
	]
}
