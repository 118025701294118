import { useState } from 'react'
import { Header, Footer } from './particles'
import { CookieConsent } from 'components/common'
import { classNames } from 'core/utils/general'

export default function MainLayout({ sticky, element, children }) {
	const [modal, setModal] = useState(false)

	const closeModal = () => setModal(!modal)

	return (
		<div className="wrapper" id="top">
			<div className={classNames('main-layout', { sticky })}>
				<Header sticky={sticky} modal={closeModal} />

				<section className="sections" ref={element}>
					{children}
				</section>

				<Footer />

				<CookieConsent>
					Cookie consent <br />
					This website uses cookies to enhance the user experience. {` `}
					<a href="/cookies" target="_blank">
						More...
					</a>
				</CookieConsent>
			</div>
		</div>
	)
}
