const localServer = false
const apiUrl = localServer ? 'http://localhost:8000' : 'https://api.biocadless.com'
const siteUrl = process.env.NODE_ENV === 'production' ? 'https://biocadhk.com' : 'http://localhost:3000'

const settings = {
	locale: 'en',
	site_name: 'BIOCAD HONG KONG',
	title: 'BIOCAD HONG KONG - Shaping the future of biotech',
	description: `BIOCAD HONG KONG is an established company which is involved in R&D, business development, marketing and commercialisingof biosimilars and innovative pharmaceuticals for the treatment of oncological, autoimmune, and orphan diseases`,
	url: siteUrl,
	background_color: '#ffffff',
	theme_color: '#0067BB',
	imagemode: 'next', // enum: ['static','next']
	metrics: {
		gtm: 'GTM-MWNBFNP', // GTM-XXXXXXX
		yandex: null, // yandex-verification
		google: null // google-site-verification
	},
	api: `${apiUrl}/v1`,
	apiupl: `${apiUrl}/uploads/biocadhk`,
	client_id: process.env.CLIENT_ID || 'biocadhk',
	client_secret: process.env.CLIENT_SECRET
}

export default settings
