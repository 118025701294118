import Cookie from 'js-cookie'
import { ConfigProvider } from 'antd'
import { createContext, useContext, useState, useEffect } from 'react'
import ru_RU from 'antd/lib/locale/ru_RU'

// import useAction from 'core/api'
// import settings from '../../settings'

const accept_notice = Cookie.get('accept_notice')

const StoreContext = createContext({})

export const StoreProvider = ({ children }) => {
	const [message, setMessage] = useState('')
	const [loading, setLoading] = useState(true)
	const [store, setStore] = useState({ user: '', isAuth: '', noticeAccept: accept_notice })
	const { user, isAuth, noticeAccept } = store

	// useEffect(() => {}, [])

	const onNoticeAccept = () => {
		Cookie.set('accept_notice', true)
		setStore({ ...store, noticeAccept: true })
	}

	return (
		<ConfigProvider locale={ru_RU}>
			<StoreContext.Provider value={{ loading, message, store, setStore, noticeAccept, onNoticeAccept }}>{children}</StoreContext.Provider>
		</ConfigProvider>
	)
}

export default function useStore() {
	return useContext(StoreContext)
}
