import { HeadSeo } from 'components/common'
import { Header } from './particles'

export default function Erorr({ children }) {
	return (
		<div className="wrapper">
			<HeadSeo slug="404" robots="noindex,nofollow" title="Error 404. Page not found" desc="Error 404. Page not found" />
			<div className="error-layout">
				<Header sticky={false} />
				{children}
			</div>
		</div>
	)
}
