import { menu } from 'core/context'
import { Link } from 'components/common'

const Footer = () => {
	const year = new Date().getFullYear()
	return (
		<>
			<footer className="footer">
				<div className="container-inner">
					<div className="row">
						<span className="copy">©BIOCAD HK Ltd, {year}</span>
						<div className="row terms">
							{menu?.terms?.map((a, i) => (
								<Link key={i} {...a?.link}>
									<a>{a.name}</a>
								</Link>
							))}
						</div>
					</div>

					<div className="bottom">
						<p>This website is owned and operated by BIOCAD Hong Kong Limited</p>
						<p>Level 54, Hopewell Centre, 183 Queen's Road East, Hong Kong SAR PRC</p>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer
