import classNames from 'classnames'
import { menu } from 'core/context'
import { Link, Menu } from 'components/common'

const Header = ({ sticky, modal }) => {
	return (
		<>
			<header className={classNames('header white', { sticky: sticky })}>
				<div className="row container-inner">
					<Link href="/">
						<a className="logo" title="HONG KONG" />
					</Link>

					<Menu list={menu?.header} />

					<div className="row right">
						<a href="#writetous" className="button" title="write to us">
							Write to us
						</a>
						{/* <Link href="/#contacts"><a className="button" title="write to us">Write to us</a></Link> */}
					</div>
				</div>
			</header>
		</>
	)
}

export default Header
