import ConsentCookie from 'react-cookie-consent'

const CookieConsent = ({ children, ...props }) => {
	return (
		<ConsentCookie
			expires={150}
			location="bottom"
			disableStyles={true}
			declineButtonText=""
			containerClasses="cookie"
			cookieName="cookieAccept"
			enableDeclineButton={true}
			declineButtonClasses="close"
			contentClasses="cookie-inner"
			buttonClasses="button cookie-accept"
			buttonWrapperClasses="cookie-buttons"
			buttonText="I agree to the transfer of cookies">
			<div className="cookie-text">{children}</div>
		</ConsentCookie>
	)
}

export default CookieConsent
