import classNames from 'classnames'
import { HeadSeo } from 'components/common'

const PageLayout = ({ pagebanner, className, children, ...props }) => {
	const { _id, slug, title, excerpt, content, source, seo } = props

	return (
		<div className={classNames(`page page-template`, `page-${slug}`, className)}>
			<HeadSeo {...seo} />
			{children}
		</div>
	)
}

export default PageLayout
